import {
  SET_NAME,
  SET_EMAIL,
  SET_ORIGIN,
  SET_REDIRECT_URL,
  SET_PUBLICKEY,
  SET_IS_CITA_FLOW,
  SET_BUTTON_URL,
  SET_CUSTOMER_ID,
  SET_CART_COUNTRY,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_CENTER,
  SET_IS_CITA_WITH_CPG,
  SET_APPOINTMENT_REDIRECT,
  SET_IS_ONLY_CPG,
  SET_IS_ALIGNERS_FLOW,
  SET_IS_ONLY_SETTLEMENT,
  SET_IS_ONLY_ADVANCE,
  SET_IS_ONLY_COMPLEMENTARY_PAYMENT,
  SET_EMPTY_ADDRESS,
  SET_RETURN_URL,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_ORIGIN:
      return {
        ...state,
        origin: action.payload,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case SET_PUBLICKEY:
      return {
        ...state,
        publicKey: action.payload,
      };
    case SET_IS_CITA_FLOW:
      return {
        ...state,
        isCitaFlow: action.payload,
      };
    case SET_BUTTON_URL:
      return {
        ...state,
        buttonUrl: action.payload,
      };
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    case SET_CART_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case SET_APPOINTMENT_DATE:
      return {
        ...state,
        appointmentDate: action.payload,
      };
    case SET_APPOINTMENT_CENTER:
      return {
        ...state,
        appointmentCenter: action.payload,
      };
    case SET_IS_CITA_WITH_CPG:
      return {
        ...state,
        isCitaWithCPG: action.payload,
      };
    case SET_APPOINTMENT_REDIRECT:
      return {
        ...state,
        appointmentRedirect: action.payload,
      };
    case SET_IS_ONLY_CPG:
      return {
        ...state,
        isOnlyCPG: action.payload,
      };
    case SET_IS_ALIGNERS_FLOW:
      return {
        ...state,
        isAlignersFlow: action.payload,
      };
    case SET_IS_ONLY_SETTLEMENT:
      return {
        ...state,
        isOnlySettlement: action.payload,
      };
    case SET_IS_ONLY_ADVANCE:
      return {
        ...state,
        isOnlyAdvance: action.payload,
      };
    case SET_IS_ONLY_COMPLEMENTARY_PAYMENT:
      return {
        ...state,
        isOnlyComplementaryPayment: action.payload,
      };
    case SET_EMPTY_ADDRESS:
      return {
        ...state,
        emptyAddress: action.payload,
      };
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      };
    default:
      return state;
  }
};

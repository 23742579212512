/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from '@futura-library/error-page';
import { PageLoader } from '@futura-library/ui-library';
import Header from '../../components/Header';
import Dashboard from '../../containers/Dashboard';
import {
  axiosGet,
  axiosGetOrder,
  axiosGetOrderId,
  axiosGetPartnerInfo,
} from '../../methods/globalData/axiosGet';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import Constants from '../../utils/Constants';
import AlertModal from '../../components/AlertModal';
import { priceCheck, timer, backCheckout } from '../../assets/images/Global';
import { axiosPutRecalculateCarts } from '../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../utils/stock';
// import { axiosPutSetShippingMethod } from '../../methods/globalData/axiosPut';
import alertModalInfo from './alertModalInfo';
// import getDataBack4app from '../../utils/back4app';
// import dateFormatter from '../../utils/dateFormatter';
import { processDiscountCodes } from '../../utils/discounts';

class CustomErrorMessage extends Error {
  constructor(message, description) {
    super(message, description);
    this.name = `${description}`;
  }
}

const Home = () => {
  const orderParam = new URLSearchParams(window.location.search).getAll('order');
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const name = new URLSearchParams(window.location.search).get('name');
  const redirectUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl',
  );
  const publicKey = new URLSearchParams(window.location.search).get(
    'redirectUrl',
  );
  const origin = new URLSearchParams(window.location.search).get(
    'origin',
  );
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'buttonUrl',
  );
  const customerId = new URLSearchParams(window.location.search).get(
    'customerId',
  );
  const returnUrl = new URLSearchParams(window.location.search).get(
    'returnUrl',
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [getCartsData, setGetCartsData] = useState([]);
  const [Items, setItems] = useState([]);
  const [cartOrdered, setCartOrdered] = useState(false);
  const [addressToModal, setAddressToModal] = useState([]);
  const [orderedType, setOrderedType] = useState('');
  const [redirectingToCart, setRedirectingToCart] = useState(true);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const {
    setOriginFunction,
    setRedirectUrlFunction,
    setPublicKeyFunction,
    setIsCitaFlowFunction,
    setButtonUrlFunction,
    setCustomerIdFunction,
    setCartCountryFunction,
    // setAppointmentDateFunction,
    // setAppointmentCenterFunction,
    setIsCitaWithCPGFunction,
    // setAppointmentRedirectFunction,
    setIsOnlyCPGFuntction,
    setIsAlignersFlowFunction,
    setIsOnlySettlementFunction,
    setIsOnlyAdvanceFunction,
    setIsOnlyComplementaryPaymentFunction,
    setNameFunction,
    setEmailFunction,
    isCitaFlow,
    country,
    phone,
    setPhoneFunction,
    setReturnUrlFunction,
    // setPhoneFunction,
    // publicKey: publicKeyContext,
  } = globalContext;
  const {
    setCarts,
    setProducts,
    setCoupons,
    setCouponsCode,
    setOrder,
    order,
  } = productContext;
  const {
    CitaId,
    // ShippingId,
    Apartado,
    PagoComplementario,
  } = Constants;

  /**
   * This function returns all Modal Data
   */
  const modalStrategies = alertModalInfo(
    isCitaFlow,
    priceCheck,
    buttonUrl,
    publicKey,
    redirectUrl,
    timer,
    addressToModal,
    backCheckout,
    setCartOrdered,
  );

  useEffect(() => {
    (async () => {
      const cart = new URLSearchParams(window.location.search).get('cart');

      if (orderParam && orderParam.length > 0) {
        let tmpOrder = order;
        if (!order) {
          const { data } = await axiosGetOrder(orderParam);
          tmpOrder = data;
          setOrder(tmpOrder);
        }

        if (!cart) {
          try {
            if (tmpOrder && tmpOrder.cart) {
              window.location.replace(`${window.location.href}&cart=${tmpOrder.cart.id}`);
              return;
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      } else {
        setOrder(null);
      }
      setRedirectingToCart(false);
    })();
  }, [window.location.search]);

  useEffect(() => {
    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(carts);

        if (!areThereCartsWithoutEnoughStock(upToDateCartData.carts)) return;

        setOrderedType('outOfStock');
        setCartOrdered(true);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        // setError(true);
      }
    })();
  }, []);
  /*
  useEffect(() => {
    if (isCitaFlow && origin !== 'payment-links') {
      setTimeout(() => {
        setOrderedType('timeOut');
        setCartOrdered(true);
      }, 10000);
    }
  }, [isCitaFlow, origin]);
  */
  useEffect(() => {
    const urlGenerated = `carts/${carts[0]}`;
    const newArrayCouponsCode = [];
    axiosGet(urlGenerated, true)
      .then((res) => {
        const { data } = res;
        setEmailFunction(data.customerEmail);
        if (country === '') {
          setCartCountryFunction(data.country.toLocaleUpperCase());
        }
        setGetCartsData([...getCartsData, data]);
        setCoupons(data.discountCodes);
        processDiscountCodes(
          res.data.lineItems,
          res.data.customLineItems,
          res.data.discountOnTotalPrice,
        ).then((responseCouponsProcess) => {
          responseCouponsProcess.forEach((discount) => {
            newArrayCouponsCode.push({
              id: discount.id,
              code: discount.code,
              discount: discount.discount,
            });
          });
          setCouponsCode(newArrayCouponsCode);
        });
        if (data.lineItems.length !== 0 || data.customLineItems.length !== 0) {
          const arrayItems = [];
          data.customLineItems.forEach((custom) => {
            arrayItems.push(custom);
          });
          data.lineItems.forEach((line) => {
            arrayItems.push(line);
          });
          if (arrayItems.length !== 0) {
            setItems([...Items, arrayItems]);
          }
        }
      })
      .catch((errorSentry) => {
        const errorToSentry = new CustomErrorMessage(errorSentry, 'Error al obtener el carrito');
        if (Sentry) {
          Sentry.captureException(errorToSentry);
        }

        if (!redirectingToCart) {
          setError(true);
        }
      });
    if (name) {
      localStorage.setItem('name', name);
      setNameFunction(name);
    } else {
      localStorage.setItem('name', 'Usuario Invitado');
      setNameFunction('Usuario Invitado');
    }
    if (redirectUrl) {
      setRedirectUrlFunction(redirectUrl);
    }
    if (publicKey) {
      let publicKeyConverted = publicKey.split('/')[3];
      [publicKeyConverted] = publicKeyConverted.split('?');
      setPublicKeyFunction(publicKeyConverted);
    }
    if (origin) {
      setOriginFunction(origin);
    }
    if (buttonUrl) {
      setButtonUrlFunction(buttonUrl);
    }
    if (customerId) {
      setCustomerIdFunction(customerId);
    }
    if (returnUrl) {
      sessionStorage.setItem('returnUrl', returnUrl);
      setReturnUrlFunction(returnUrl);
    } else {
      sessionStorage.removeItem('returnUrl');
    }
  }, [name, redirectUrl, publicKey, origin, buttonUrl, customerId]);

  useEffect(() => {
    if (carts.length === getCartsData.length) {
      setCarts(getCartsData);
      setProducts(Items);
      if (Items.length !== 0) {
        if (Items[0].length === 1) {
          const [[product]] = Items;
          if (product.productId === CitaId) {
            setIsCitaFlowFunction(true);
            try {
              if (window.dataLayer) {
                console.log('GA4-checkout-appointment');
                window.dataLayer.push({ event: 'checkout-appointment' });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            setIsCitaFlowFunction(false);
          }
          if (product?.custom?.fields?.productKey === 'ALDRS') {
            setIsOnlySettlementFunction(true);
          } else {
            setIsOnlySettlementFunction(false);
          }
          if (product.productId === Apartado) {
            setIsOnlyAdvanceFunction(true);
          } else {
            setIsOnlyAdvanceFunction(false);
          }
          if (product.productId === PagoComplementario) {
            setIsOnlyComplementaryPaymentFunction(true);
          } else {
            setIsOnlyComplementaryPaymentFunction(false);
          }
          // Get Appointment Information
          /* if (false) {
             getDataBack4app('Patient', publicKeyContext, null)
              .then((responeDealAppointment) => {
                if (responeDealAppointment.data.Patient.Appointment_Date) {
                  const appointmentDate = dateFormatter(
                    responeDealAppointment.data.Patient.Appointment_Date.iso,
                    responeDealAppointment.data.Patient.Timezone,
                    responeDealAppointment.data.Patient.Country_Ops,
                  );
                  setAppointmentDateFunction(appointmentDate);
                  setAppointmentCenterFunction(responeDealAppointment.data.Patient.Calendar);
                }
                if (
                  (responeDealAppointment.data.Patient.Appointment_Status
                  !== 'Scheduled'
                  && responeDealAppointment.data.Patient.Appointment_Status
                  !== 'Canceled')
                ) {
                  if (product.productId === CitaId) {
                    setAppointmentRedirectFunction(true);
                  } else {
                    setAppointmentRedirectFunction(false);
                  }
                }
              });
            }
            */
        } else {
          const isOnlyCPG = Items[0].find((item) => {
            return (
              item.productId === Apartado
              || item.productKey === 'ALDRS'
              || item.productId === PagoComplementario
              || item.productId === CitaId
            );
          });
          if (isOnlyCPG === undefined && origin === 'payment-links') {
            setIsOnlyCPGFuntction(true);
          } else {
            setIsOnlyCPGFuntction(false);
          }
          const isAlignersFlow = Items[0].find((item) => {
            return (
              item.productId === Apartado
              || item.productKey === 'ALDRS'
              || item.productId === PagoComplementario
            );
          });
          if (isAlignersFlow !== undefined) {
            setIsAlignersFlowFunction(true);
          } else {
            setIsAlignersFlowFunction(false);
          }
          const isCitaWithCPG = Items[0].find((item) => {
            return item.productId === CitaId;
          });
          if (isCitaWithCPG !== undefined) {
            setIsCitaWithCPGFunction(true);
          } else {
            setIsCitaWithCPGFunction(false);
          }
          // Get Appointment Information
          /* getDataBack4app('Patient', publicKeyContext, null)
            .then((responeDealAppointment) => {
              if (responeDealAppointment.data.Patient.Appointment_Date) {
                const appointmentDate = dateFormatter(
                  responeDealAppointment.data.Patient.Appointment_Date.iso,
                  responeDealAppointment.data.Patient.Timezone,
                  responeDealAppointment.data.Patient.Country_Ops,
                );
                setAppointmentDateFunction(appointmentDate);
                setAppointmentCenterFunction(responeDealAppointment.data.Patient.Calendar);
              }
              if (
                (responeDealAppointment.data.Patient.Appointmentx_Status
                  !== 'Scheduled'
                  && responeDealAppointment.data.Patient.Appointment_Status
                  !== 'Canceled')
              ) {
                if (isCitaWithCPG !== undefined || isCitaFlow) {
                  setAppointmentRedirectFunction(true);
                } else {
                  setAppointmentRedirectFunction(false);
                }
              }
            }).catch((ex) => {
              console.log(ex);
            });
            */
        }
      }
      setLoading(false);
    }
  }, [getCartsData, Items]);

  if (addressToModal.length === 0 && getCartsData.length > 0) {
    setAddressToModal(getCartsData[0]);
  }

  useEffect(() => {
    if (getCartsData[0]) {
      axiosGetOrderId(getCartsData[0].id)
        .then((response) => {
          if (response.data.results.length !== 0) {
            setCartOrdered(true);
            if (response.data.results[0].paymentState === 'Paid') {
              setOrderedType('paid');
            } else if (!orderParam) {
              setOrderedType('pending');
            }
          }
        })
        .catch((errorOrderId) => {
          // eslint-disable-next-line
          console.log(errorOrderId);
        });
      // TODO: Uncomment when shipping method is ready
      // if (!getCartsData[0].shippingInfo) {
      //   const shippingMethod = {
      //     id: ShippingId,
      //     typeId: 'shipping-method',
      //   };
      //   axiosPutSetShippingMethod(carts, shippingMethod);
      // }
    }
  }, [getCartsData[0]]);

  useEffect(async () => {
    if (getCartsData[0] && phone === '') {
      try {
        const res = await axiosGetPartnerInfo(getCartsData[0].customerId);
        console.log('res', res);
        if (res?.data?.results[0]?.phone) {
          setPhoneFunction(res?.data?.results[0]?.phone);
        }
      } catch (e) {
        console.log('Error getting phone', e);
        setPhoneFunction('');
      }
      // setPhoneFunction('');
    }
  }, [getCartsData[0]]);

  if (loading && !error) {
    return <PageLoader />;
  }

  if (error || (carts.length === 0)) {
    if (!redirectingToCart) {
      return <ErrorPage />;
    }
  }

  return (
    <>
      <Header />
      <Dashboard />
      {cartOrdered && orderedType !== '' && (
        <AlertModal
          data={modalStrategies[orderedType].data}
          modal={modalStrategies[orderedType].modal && setCartOrdered}
          discardOption={modalStrategies[orderedType].discardOption}
        />
      )}
    </>
  );
};

export default Home;
